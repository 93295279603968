/* Style the table headers */
.table-responsive table thead th {
    background-color: #333;
    color: #fff;
}

/* Style the table rows */
.table-responsive table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Style table cells */
.table-responsive table td {
    padding: 8px;
}

/* Add some spacing around the table */
.table-responsive {
    margin: 20px;
}