
.home_content {
    margin-top: 50px;
}

.container-fluid.pad {
    padding: 0 10px;
}


h1.head_prod {
    text-align: center;
    padding: 20px 0;
}


.card .img-wrap{
    width: 100%;
    height: 256px;
}


/* .img-contain{
     width: 100%;
     height: 150px;
} */

@media only screen and (max-width: 1023px) and (min-width: 768px) {

   
}

.banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-container {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .slider {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
  
  .slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .slider-button.left {
    left: 0;
  }
  
  .slider-button.right {
    right: 0;
  }
  