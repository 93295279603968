.info ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
}

.info li{
    padding: 0 20px;
    font-size: 18px;
}

.add_bor {
    border: none;
    width: 100px;
}

.zoom-on-hover {
    transition: transform 0.2s ease-in-out;
  }
  
  .zoom-on-hover:hover {
    transform: scale(1.1); /* Adjust the scale factor as desired */
  }

  .btn-bounce-on-hover:hover i {
    animation: bounce 0.5s infinite;
    color: rgb(232, 230, 230);
  }