
.order-com{
    padding: 5px 5px 0px 5px;
  
    border: 1px solid rgb(201, 200, 200);
    border-top-right-radius:5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius:5px;
    border-bottom-left-radius: 5px;
}
.main_order{
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}

.order_table{
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.title-span{
    color: #6F6F6F!important;
}