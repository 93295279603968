section.order_page {
    padding: 50px 30px;
}

/* .tab_bor {
    border: 1px solid #000;
} */

.col_2_bor {
    /* border-right: 1px solid; */
    padding: 0;
}

div#v-pills-tabContent {
    padding: 0;
}

.card-body {
    padding: 0;
}


.nav-link {
    padding: 15px 20px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-left: none;
    border-right: none;
}

.moving-note-container {
    overflow: hidden;
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
  }
  
  .moving-note {
    white-space: nowrap;
    position: absolute;
    left: -100%;
    animation: slideNote 10s linear infinite;
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    padding: 10px;
    border-radius: 5px;
  }
  
  @keyframes slideNote {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  