.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  background-color: #f8f9fa;
}

.login-container {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* width: 100%; */
  max-width: 400px;
  text-align: center;
}
.login-box-custom{
  margin-bottom: 130px;
}
.login-title {
  color: #0056b3;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-label {
  text-align: left;
  margin-bottom: 0.5rem;
  color: #333;
}

.login-input {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.login-input {
  width: 100%;
  padding-right: 40px; /* Adjust padding to make space for the icon */
}

.password-toggle-icon {
  position: absolute;
  right: 10px; /* Adjust the position as needed */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.login-button {
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.register-btn{
  /* border-color: #0056b3; */
  /* color: #fff; */
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  /* cursor: pointer; */
}

.login-button:hover {
  background-color: #0056b3;
}

.login-error {
  color: #dc3545;
}

.login-form p {
  margin-top: 1rem;
  color: #007bff;
}

.login-form p a {
  color: #007bff;
  text-decoration: none;
}

.login-form p a:hover {
  text-decoration: underline;
}
.shop-image{
  display: flex;
  height: 70vh;
  width: 70vh;
  /* margin-right: 100px; */
}
