.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

/* .register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #0056b3;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
  max-height: 650px;
  background: #fff;
  margin-top: 50px;
}

.register-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.register-image img {
  max-width: 80%;
  height: auto;
} */


.register-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Changed from 100% to auto for better responsiveness */
  text-align: center;
}

.register-image img {
  max-width: 90%; /* Changed from 80% to 100% for full responsiveness */
  height: auto;
}

.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #fa8232;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Changed from 80% to 90% for better responsiveness */
  max-width: 600px;
  max-height: auto; /* Changed from 650px to auto for better responsiveness */
  background: #fff;
  margin-top: 50px;
  margin: 15px;
}

.register-title {
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  color:#fa8232;
  margin-bottom: 20px;
}

.register-form {
  width: 100%;
}



.input-group {
  margin-bottom: 20px;
}

.register-label {
  color: #666;
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}

.register-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}


.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.register-button {
  width: 100%;
  padding: 12px;
  font-weight: bold;
  background:#fa8232;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.register-button:hover {
  background: #fa8232;
}

.register-error {
  color:#fa8232;
  text-align: center;
  margin-top: 10px;
}

.reg_log {
  text-align: center;
  padding: 10px 0 0 0;
}
