/* 
@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact !important;
    }
    thead {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        background-color: #343A40 !important;
      }

    @page {
        size: auto;
        margin: 20mm;
    }

    .print-area {
        margin: 0 !important;
    }

    .prints {
        margin: 50px !important;
    }

    .order {
        margin-bottom: 30px !important;
        background-color: rgba(128, 113, 113, 0.2) !important;
    }

    img {
        margin-bottom: 10px !important;
    }

    p {
        font-weight: bolder !important;
    }

    .pb-3.pr-4 {
        display: none !important;
    }

    .table {
        --bs-table-color: var(--bs-body-color);
        --bs-table-bg: transparent;
        --bs-table-border-color: var(--bs-border-color);
        --bs-table-accent-bg: transparent;
        --bs-table-striped-color: var(--bs-body-color);
        --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
        --bs-table-active-color: var(--bs-body-color);
        --bs-table-active-bg: rgba(0, 0, 0, 0.1);
        --bs-table-hover-color: var(--bs-body-color);
        --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
        width: 100%;
        margin-bottom: 1rem;
        color: var(--bs-table-color);
        vertical-align: top;
        border-color: var(--bs-table-border-color);
    }

    .print-area .table thead {
        background-color: black !important;
        color: white !important;
        -webkit-print-color-adjust: exact;
    }

    .print-area .table th {
        color: white !important;
        border: 1px solid black !important;
    }

    .print-area .table td {
        border: 1px solid black !important;
    }
} */


@media print {
    /* Reset height and overflow for printing */
    html,
    body {
      height: initial;
      overflow: initial;
    }
  
    /* Set print colors to exact */
    .table thead {
        background-color: #343A40!important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        color: #FFFFFF;
        border: 1px solid #000000;
      }
      
      .table th {
        background-color: #343A40!important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        color: #FFFFFF;
        border: 1px solid #000000;
      }

    * {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
  
    /* Print styles */
   .print-area {
      margin: 0;
    }
  
   .prints {
      margin: 50px;
    }
  
   .order {
      margin-bottom: 30px;
      background-color: rgba(128, 113, 113, 0.2);
    }
  
    img {
      margin-bottom: 10px;
    }
  
    p {
      font-weight: bold;
    }
  
   .pb-3.pr-4 {
      display: none;
    }
  
    /* Table styles */
   .table {
      width: 100%;
      margin-bottom: 1rem;
      border-collapse: collapse;
    }
  
   .table thead {
      background-color: #343A40;
      color: #FFFFFF;
    }
  
   .table th {
      color: #FFFFFF;
      border: 1px solid #000000;
    }
  
   .table td {
      border: 1px solid #000000;
    }
    
    /* OrderSingledata.css */

/* Existing styles */
.text-auto-color {
  color: #333;
}

/* Styles for printing */
@media print {
  /* Reset height and overflow for printing */
  html,
  body {
      height: initial;
      overflow: initial;
  }

  /* Set print colors to exact */
  * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
  }

  .table thead, .table th {
      background-color: #343A40 !important;
      color: #FFFFFF;
      border: 1px solid #000000;
  }

  /* Print styles */
  .print-area {
      margin: 0;
  }

  .prints {
      margin: 50px;
  }

  .order {
      margin-bottom: 30px;
      background-color: rgba(128, 113, 113, 0.2);
  }

  img {
      margin-bottom: 10px;
  }

  p {
      font-weight: bold;
  }

  .pb-3.pr-4 {
      display: none;
  }

  /* Table styles */
  .table {
      width: 100%;
      margin-bottom: 1rem;
      border-collapse: collapse;
  }

  .table td {
      border: 1px solid #000000;
  }

  /* Hide review column */
  .review-column {
      display: none;
  }
  /* Page margins */
  @page {
      size: auto;
      margin: 20mm;
  }
}


    /* Page margins */
    @page {
      size: auto;
      margin: 20mm;
    }
  }
  @media print {
    .review-column {
        display: none;
    }

    
}