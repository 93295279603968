.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
      stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
      transform: none;
  }

  50% {
      transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
      box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}
  
  .container {
    text-align: center;
  }